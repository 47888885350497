import React, { useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material';
import { formatDate } from "../../../../utils/helpers";
import { useSelector } from "react-redux";
import PageLoader from "../../../../components/Loaders/PageLoader";
import { truzoPaymentStatus, truzoPaymentStatusSx } from "../../../../utils/truzo";


const paymentStatus = {
  "Released": "Paid",
  "In Trust": "In Process",
  "Pending": "Pending"
}

const paymentStatusSx = (status) => {
  return {
    backgroundColor: status === paymentStatus.Released ? '#34C7591A' : status === paymentStatus["In Trust"] ? "#F996004D" : '#FF3B301A',
    color: status === paymentStatus.Released ? '#34C759' : status === paymentStatus["In Trust"] ? "#FF8008" : '#FF3B30',
  }
}
// Table component
const TransactionTable = ({ data, isLoading }) => {
  const profile = useSelector(state => state.profile);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  // console.log("data", data, profile)
  const userId = profile?.basicInfo?.userId;


  const rows = useMemo(() => {
    return data?.map((row) => {
      const isBuyer = row?.actors?.buyer?._id === userId;
      const isSeller = row?.actors?.seller?._id === userId;
      console.log("userId", {
        gigName: row?.name,
        userId,
        buyerId: row?.actors?.buyer?._id,
        sellerId: row?.actors?.seller?._id,
        isBuyer,
        isSeller,
      })
      return {
        amount: row?.amounts?.amount,
        gigName: row?.name,
        projectType: row?.type?.display_name,
        date: formatDate(row?.funded_date),
        status: truzoPaymentStatus[row?.payment_status],
        transactionType: isBuyer ? "debit" : "credit",
      }
    }) || []
  }, [data, userId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, mt: 4 }}>
        <TableHead sx={{ background: "#F5F6FA", "& td, & th": { border: 0 }, borderRadius: "5px" }}>
          <TableRow>
            <TableCell>Contract type</TableCell>
            <TableCell>Project</TableCell>
            <TableCell>Debit/Credit</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Remark</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            isLoading &&
            <TableRow>
              <TableCell colSpan={6}>
                <PageLoader
                  sx={{
                    textAlign: 'center',
                    minHeight: '200px',
                  }} />
              </TableCell>
            </TableRow>
          }
          {rows
            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .slice(0, 20)
            .map((row) => (
              <TableRow
                key={row.id}
                sx={{ '& td, & th': { border: 0 } }}
              >
                <TableCell>{row?.projectType || "-"}</TableCell>
                <TableCell>{row?.gigName || "-"}</TableCell>
                {/* <TableCell sx={{ color: row?.transactionType === "debit" ? "#34C759" : "#FF3B30" }}>{row?.amount ? row?.amount : "-"}
                </TableCell> */}
                <TableCell>{row?.amount ? row?.amount : "-"}
                </TableCell>
                <TableCell>
                  <span
                    style={{
                      padding: '6px 12px',
                      borderRadius: '16px',
                      ...truzoPaymentStatusSx(row?.status),
                    }}
                  >
                    {row?.status}
                  </span>
                </TableCell>
                <TableCell>{row?.remark || "-"}</TableCell>
                <TableCell>{row?.date}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </TableContainer>
  );
}

export default TransactionTable
