import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Typography, Dialog, Box, DialogTitle, IconButton, DialogContent, Stack, Avatar, DialogActions, Divider, TextField, InputAdornment, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { GiglinkInput } from '../../containers/app.styles';
import { copyToClick, formatDate, getDurationLabel } from '../../utils/helpers';
import { formatDistance } from 'date-fns';
import ReadMore from '../ReadMore';
import Boundingbox from '../../assests/attach_file.png';
import { saveProposal } from '../../services/proposal';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { Delete, Edit } from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import * as yup from 'yup';

function HorizontalLine() {
  return <Box sx={{ border: "1px solid #D5E0D5", my: 2 }} />
}
const GigProposalEngineer = ({ open, setOpen, data, callback }) => {
  const [gig, setGig] = useState();
  const [readMoreDetails, setReadMoreDetails] = useState(false)
  const [seeMore, setSeeMore] = useState(false);
  const profile = useSelector(state => state.profile);
  const [milestones, setMilestones] = useState([]);
  const [startDate, setStartDate] = useState();
  const [startDateEdit, setStartDateEdit] = useState(false);
  const [editMilestones, setEditMilestones] = useState(false);
  const [checked, setChecked] = useState(false);
  const formRef = useRef();


  useEffect(() => {
    setGig(data);

  }, [data]);

  const cleanUp = () => {
    setMilestones([]);
    setStartDate();
    setStartDateEdit(false);
    setEditMilestones(false);
    setGig();
  }

  const handleClose = () => {
    setOpen(false);
    if (callback) {
      callback()
    }
    cleanUp();
  };


  const engineerData = useMemo(() => {
    return {
      name: profile?.basicInfo?.name || "",
      title: profile?.myProfile?.title || "",
      profilePic: profile?.myProfile?.profilePic || "",
      id: profile?.basicInfo?.userId,
      publicProfile: `${window.location.origin}/profile/${profile?.myProfile?.userName}`,
      posted: data?.createdAt ? formatDistance(
        new Date(data?.createdAt),
        new Date(),
        { addSuffix: true }
      ) : ""
    }
  }, [data]);

  const getExpertiseList = (expertise) => {
    return Array.prototype.map.call(expertise, (s) => ` ${s}`).toString();
  };

  const handleProposal = async () => {
    try {
      const gigId = data._id;
      const requestData = {
        "milestones": milestones && milestones.length > 0 ? milestones : data.milestones,
      }
      if (startDate) {
        requestData.startDate = startDate;
      }
      const res = await saveProposal(gigId, data.applicationDetails._id, requestData);
      if (res.status === 200) {
        toast.success(res.message || "Proposal sent successfully")
      }
      handleClose()
    } catch (error) {

    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          "& .pic": {
            width: "80px",
            height: "100%",
            objectFit: "contain",
            borderRadius: "50%"
          },
          "& .close": {
            width: "72px",
            height: "100%",
            objectFit: "contain"
          }
        }}>
        <Stack
          sx={{ pt: 2 }}>
          {
            engineerData?.profilePic ?
              <img
                src={engineerData.profilePic}
                alt='pic'
                className='pic' />
              : <Avatar
                src={""}
                sx={{
                  width: 80,
                  height: 80,
                  boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                  margin: 'auto'
                }}
              />
          }
          <Typography
            sx={{
              mt: 1,
              fontSiz: "14px",
              fontWeight: 600
            }}>
            {engineerData.name}
          </Typography>
        </Stack>
        <IconButton
          disableRipple
          onClick={handleClose}
          size='small'>
          <img src={"/images/close.png"} alt='close' className='close' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}>
          <Stack>
            <Typography
              component="h5"
              sx={{
                fontSize: "35px",
                fontWeight: 400,
                lineHeight: "35px",
                color: "#001E00",
              }}>
              {engineerData.title}
            </Typography>
            <Typography
              sx={{
                mt: 2,
                fontSize: "13px",
                lineHeight: "16px",
                color: "#5E6D55"
              }}>
              Posted {engineerData.posted}
            </Typography>
          </Stack>
          <div className="Gig-link-gig-details">
            <div className="Gig-link-Text">Gig Link</div>
            <GiglinkInput
              value={engineerData.publicProfile}
              id="CopytoClickid"
              title={engineerData.publicProfile}
              disabled
            />
            <div className="Copy-Gig-link-Btn" onClick={copyToClick}>
              <Button className='copy-btn-gig-details'>
                Copy Link
              </Button>

            </div>
          </div>
        </Stack>
        <HorizontalLine />
        <div className="Project-Detail-Container">
          <div className="PD-title-Text">Project Details</div>
          <Box sx={{ fontSize: '15.5px', color: 'black' }}>
            {!!_get(gig, 'titleAndSkills.projectDetails', '') && (
              <div
                style={{ maxHeight: readMoreDetails ? 'fit-content' : 'auto', overflow: 'hidden', }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: _get(gig, 'titleAndSkills.projectDetails', '')
                  }}
                ></div>
              </div>
            )}

            {!!_get(gig, 'titleAndSkills.projectDetails', '') &&
              _get(gig, 'titleAndSkills.projectDetails', '').length > 353 && (
                <button
                  className="project-title-see-more-btn"
                  onClick={() => {
                    setReadMoreDetails(!readMoreDetails);
                  }}
                >{`${readMoreDetails ? 'See Less' : 'See More'}`}</button>
              )}
          </Box>
        </div>
        <HorizontalLine />
        <Box className="Skills-Container-gig-details">
          <Box className="Skills-Required">
            <Box className="Skills-Required-Flex">
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Location.svg"
                width={20}
              />
              <Box className="Skills-Required-title-Text">Location</Box>
            </Box>
            <Box className="Skills-Required-Location">
              {gig?.deliverablesAndLocation?.location?.country
                ? gig?.deliverablesAndLocation?.location?.country
                : 'Remote'}
            </Box>
          </Box>

          <Box className="Skills-Required">
            <Box className="Skills-Required-Flex">
              {gig?.scopeAndBudget?.durationUnit === 'PERHOUR' ? (
                <img
                  src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Hours.svg"
                  width={20}
                />
              ) : gig?.scopeAndBudget?.durationUnit === 'PERDAY' ? (
                <img
                  src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Days.svg"
                  width={20}
                />
              ) : gig?.scopeAndBudget?.durationUnit === 'PERWEEK' ? (
                <img
                  src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Weeks.svg"
                  width={20}
                />
              ) : gig?.scopeAndBudget?.durationUnit === 'PERMONTH' ? (
                <img
                  src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Months.svg"
                  width={20}
                />
              ) : (
                <img
                  src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Hours.svg"
                  width={20}
                />
              )}
              <Box className="Skills-Required-title-Text">Duration</Box>
            </Box>
            <Box className="Skills-Required-subText">
              {_get(gig, 'scopeAndBudget.projectDuration', '-')}{' '}
              {getDurationLabel(_get(gig, 'scopeAndBudget.durationUnit'))}
            </Box>
          </Box>

          <Box className="Skills-Required">
            <Box className="Skills-Required-Flex">
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Budget.svg"
                width={20}
              />
              <Box className="Skills-Required-title-Text">Budget</Box>
            </Box>
            <Box className="Skill-Required-Budget">
              {' '}
              {_get(gig, 'scopeAndBudget.currency', '-')}{' '}
              {_get(gig, 'scopeAndBudget.budget', '-')}
            </Box>
          </Box>

          <Box className="Skills-Required">
            <Box className="Skills-Required-Flex">
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Availability+Required.svg"
                width={12}
              />
              <Box className="Skills-Required-title-Text">Availability Required</Box>
            </Box>

            <Box className="Skills-Required-subText">
              {' '}
              {_get(gig, 'scopeAndBudget.availabilityHoursPerWeek', '')} Hours Per Week
            </Box>
          </Box>

          <Box className="Skills-Required" sx={{ display: "flex", alignItems: "flex-start" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box className="Skills-Required-Flex">
                <img
                  src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Start+Date.svg"
                  width={16}
                />
                <Box className="Skills-Required-title-Text">Start Date</Box>
                {
                  startDateEdit ?
                    <Button
                      onClick={() => {
                        setStartDateEdit(false);
                      }}
                      size='small'
                      sx={{ textDecoration: "underline !important", textTransform: "none" }}>
                      Save
                    </Button>
                    :
                    <Button
                      onClick={() => {
                        if (!startDate) {
                          setStartDate(data?.scopeAndBudget?.startDate);
                        }
                        setStartDateEdit(true);
                      }}
                      size='small'
                      sx={{ textDecoration: "underline !important", textTransform: "none" }}>
                      Edit
                      <Edit fontSize='small' sx={{ ml: "4px", fontSize: "14px" }} />
                    </Button>
                }
              </Box>

              {
                startDateEdit ?
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      openTo="year"
                      views={['year', 'month', 'day']}
                      name="startDate"
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(newValue || (newValue !== "Invalid Date" ? newValue : ""));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size='small'
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                  :
                  <Box className="Skills-Required-subText">
                    {
                      startDate ?
                        formatDate(startDate || "")
                        :
                        (gig?.scopeAndBudget?.startDate
                          ? formatDate(gig.scopeAndBudget.startDate || "")
                          : '-')
                    }
                  </Box>
              }
            </Box>

          </Box>

          {
            gig?.scopeAndBudget?.hourlyRate === "" || gig?.scopeAndBudget?.hourlyRate === 0 ?
              ""
              :
              <Box className="Skills-Required">
                <Box className="Skills-Required-Flex">
                  <img
                    src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Hourly+Rate.svg"
                    width={16}
                  />
                  <Box className="Skills-Required-title-Text">Hourly Rate</Box>
                </Box>
                <Box className="Skills-Required-subText">
                  {_get(gig, 'scopeAndBudget.hourlyRate', '0')
                    ? `${_get(gig, 'scopeAndBudget.currency', 'R')} ${_get(
                      gig,
                      'scopeAndBudget.hourlyRate',
                      '0'
                    )}/hr`
                    : '-'}
                </Box>
              </Box>
          }

          <Box className="Skills-Required">
            <Box className="Skills-Required-Flex">
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Area+Of+Expertise.svg"
                width="8%"
              />
              <Box className="Skills-Required-title-Text">Area of Expertise</Box>
            </Box>
            <Box className="Skills-Required-subText">
              {' '}
              {getExpertiseList(_get(gig, 'fieldOfSpecialization', []))}
            </Box>
          </Box>
        </Box>
        <div className="Tand-Ccontainer">
          <div className="break-line-gig-details"></div>
          <Stack
            sx={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
            <div className="TandC-title-Text">Milestones</div>
            {
              gig?.milestones?.length > 0 &&
              <>
                {
                  !editMilestones &&
                  <Button
                    onClick={() => {
                      if (!milestones.length > 0) {
                        setMilestones(data.milestones);
                      }
                      setEditMilestones(true);
                    }}
                    size='small'
                    sx={{ mt: 2, textDecoration: "underline", textTransform: "none" }}>
                    Edit
                    <Edit fontSize='small' sx={{ ml: "4px", fontSize: "14px" }} />
                  </Button>
                }
              </>
            }

          </Stack>
          {
            editMilestones ?
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '28px', px: 0 }} className='step2-currency'>

                <Formik
                  initialValues={{ milestones: milestones }}
                  enableReinitialize
                  innerRef={formRef}
                  validationSchema={yup.object({
                    milestones: yup.array()
                      .of(
                        yup.object().shape(
                          {
                            "title": yup.string().required("Title is required"),
                            "description": yup.string().required("Description is required"),
                            "deadline": yup.date()
                              .typeError('Deadline must be a date')
                              .test('is-after-start-date', 'Expected Completion Date must be later than start date', function (value,) {
                                if (startDate && value && value <= new Date(startDate) && value > new Date()) {
                                  return false; // Validation fails if deadline is before start date
                                }
                                return true; // Validation passes if deadline is after start date
                              })
                              .required("Deadline is required").nullable(),
                            "amount": yup.string().positive("It should be a positive number").required("Amount is required"),
                            "deliverables": yup.array().optional()
                          }
                        )
                      )
                  })}
                  onSubmit={async (values) => {
                    setMilestones(values.milestones);
                    setEditMilestones(false);
                  }}
                >
                  {({ values, setFieldValue, errors, touched }) => (
                    <Box>
                      {
                        values.milestones.map((itm, idx) => (
                          <Box key={`milestone-${idx}`} className='milestone-wrapper' sx={{ mb: 2 }}>
                            <div className='milestone-header'>
                              <h2>Milestone {idx + 1}</h2>
                              {
                                (idx > 0
                                  &&
                                  <button onClick={() => {
                                    const updatedMilestones = values.milestones.filter((_, i) => i !== idx);
                                    setFieldValue("milestones", updatedMilestones);
                                  }}>
                                    <Delete color='error' />
                                  </button>)
                              }
                            </div>
                            <div className='milestone-form-wrapper'>
                              <TextField
                                fullWidth
                                name="milestoneTitle"
                                label="Title"
                                value={values.milestones[idx]?.title || ""}
                                onChange={(e) => {
                                  const updatedMilestones = values.milestones.map((milestone, i) =>
                                    i === idx
                                      ? { ...milestone, title: e.target.value }
                                      : milestone
                                  );
                                  setFieldValue("milestones", updatedMilestones);
                                }}
                                type="text"
                                required
                                placeholder="Enter Title"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                error={
                                  touched?.milestones?.[idx]?.title
                                  &&
                                  Boolean(errors?.milestones?.[idx]?.title)
                                }
                                helperText={
                                  touched?.milestones?.[idx]?.title && errors.milestones?.[idx]?.title
                                }
                              />
                              <TextField
                                name="milestoneDescription"
                                fullWidth
                                value={values.milestones[idx]?.description || ""}
                                onChange={(e) => {
                                  const updatedMilestones = values.milestones.map((milestone, i) =>
                                    i === idx
                                      ? { ...milestone, description: e.target.value } // Update description
                                      : milestone
                                  );
                                  setFieldValue("milestones", updatedMilestones); // Trigger state update
                                }}
                                label="Description"
                                variant="outlined"
                                required
                                multiline
                                rows={4}
                                error={
                                  touched?.milestones?.[idx]?.description
                                  &&
                                  Boolean(errors?.milestones?.[idx]?.description)
                                }
                                helperText={
                                  touched?.milestones?.[idx]?.description && errors?.milestones?.[idx]?.description
                                }
                              />
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  label="Expected Completion Date"
                                  openTo="year"
                                  disablePast
                                  views={['year', 'month', 'day']}
                                  required
                                  name="deadline"
                                  value={values.milestones[idx].deadline}
                                  onChange={(newValue) => {
                                    const updatedMilestones = values.milestones.map((milestone, i) =>
                                      i === idx
                                        ? { ...milestone, deadline: newValue || (newValue !== "Invalid Date" ? newValue : "") }
                                        : milestone
                                    );
                                    setFieldValue("milestones", updatedMilestones);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      value={values.milestones[idx].deadline}

                                      fullWidth
                                      error={
                                        touched?.milestones?.[idx]?.deadline
                                        &&
                                        Boolean(errors?.milestones?.[idx]?.deadline)
                                      }
                                      helperText={
                                        touched?.milestones?.[idx]?.deadline && errors?.milestones?.[idx]?.deadline
                                      }
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                              <TextField
                                fullWidth
                                name="milestoneAmount"
                                label="Amount"
                                value={values.milestones[idx].amount || ""}
                                onChange={(e) => {
                                  const updatedMilestones = values.milestones.map((milestone, i) =>
                                    i === idx
                                      ? { ...milestone, amount: parseInt(e.target.value) || (e.target.value !== "Invalid Date" ? parseInt(e.target.value) : 0) }
                                      : milestone
                                  );
                                  setFieldValue("milestones", updatedMilestones);
                                }}
                                type="number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">{data?.scopeAndBudget?.currency}</InputAdornment>
                                  )
                                }}
                                required
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                error={
                                  touched?.milestones?.[idx]?.amount
                                  &&
                                  Boolean(errors?.milestones?.[idx]?.amount)
                                }
                                helperText={
                                  touched?.milestones?.[idx]?.amount && errors?.milestones?.[idx]?.amount
                                }
                              />
                              {
                                itm.deliverables.map((d, j) => {
                                  return (
                                    <Stack gap={2}>
                                      <TextField
                                        fullWidth
                                        name="deliverableTitle"
                                        label="Deliverable Title"
                                        value={values.milestones[idx].deliverables[j]?.deliverableTitle || ""}
                                        onChange={(e) => {
                                          const updatedMilestones = values.milestones.map((milestone, i) =>
                                            i === idx
                                              ? {
                                                ...milestone,
                                                deliverables: milestone.deliverables.map((d, k) => k === j ? { ...d, deliverableTitle: e.target.value } : d)
                                              }
                                              : milestone
                                          );

                                          setFieldValue("milestones", updatedMilestones);
                                        }}
                                        type="text"
                                        required
                                        placeholder="Enter Title"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                      />
                                      <TextField
                                        fullWidth
                                        name="deliverableDesc"
                                        label="Deliverable Description"
                                        value={values.milestones[idx].deliverables[j]?.deliverableDesc || ""}
                                        onChange={(e) => {
                                          const updatedMilestones = values.milestones.map((milestone, i) =>
                                            i === idx
                                              ? {
                                                ...milestone,
                                                deliverables: milestone.deliverables.map((d, k) => k === j ? { ...d, deliverableDesc: e.target.value } : d)
                                              }
                                              : milestone
                                          );

                                          setFieldValue("milestones", updatedMilestones);
                                        }}
                                        type="text"
                                        required
                                        placeholder="Enter Title"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                      />
                                    </Stack>
                                  )
                                })
                              }
                            </div>
                          </Box>
                        ))
                      }
                      <Stack
                        sx={{
                          flexDirection: "row",
                          justifyContent: "space-between"
                        }}>
                        <Button
                          variant="outlined"
                          sx={{ mt: 2, }}
                          onClick={() =>
                            setFieldValue("milestones", [...values.milestones, {
                              title: "",
                              description: "",
                              deadline: "",
                              amount: "",
                              deliverables: []
                            }])
                          }
                        >
                          + Add Milestone
                        </Button>

                        <Stack
                          sx={{
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                            mt: 2,
                            "button": {
                              textTransform: "none",
                              textDecoration: "underline !important",
                            }
                          }}>
                          <Button
                            size='small'
                            color='error'
                            onClick={() => {
                              setEditMilestones(false);
                              setMilestones(data.milestones);
                            }}>
                            Cancel
                            <CancelIcon fontSize='small' sx={{ ml: 1 }} />
                          </Button>
                          <Button
                            size='small'
                            onClick={() => {
                              console.log(formRef)
                              formRef?.current?.submitForm()
                            }}>
                            Save
                            <DoneIcon fontSize='small' sx={{ ml: 1 }} />
                          </Button>
                        </Stack>
                      </Stack>

                    </Box>
                  )}
                </Formik>
              </Box> :
              (milestones.length > 0 ? milestones : gig?.milestones)?.map((milestone, index) => (
                <div className="TandC-Sub-Text" key={index}>
                  <div className="TandC-Sub-Text-Title">
                    {
                      milestone?.title
                    }
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1,
                      "& span": {
                        fontSize: "14px",
                        mr: 1
                      }
                    }}>
                    <Box>
                      <Typography component={"span"}>
                        Milestone Amount:
                      </Typography>
                      <Typography component={"span"}>
                        {milestone.amount}{gig?.scopeAndBudget.currency || 'R'}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography component={"span"}>
                        Milestone End date:
                      </Typography>
                      <Typography component={"span"}>
                        {
                          formatDate(milestone.deadline)
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    className="TandC-Sub-Text-Title"
                    sx={{
                      "& .gig-detail-see-more": {
                        fontWeight: 400
                      }
                    }}>
                    <ReadMore>
                      {
                        milestone?.description
                      }
                    </ReadMore>
                  </Box>
                  <div>
                    <div className="TandC-title-Text">Deliverables</div>
                    <div className="TandC-Sub-Text">
                      {milestone?.deliverables?.length > 0 ? (
                        <>
                          {
                            milestone?.deliverables?.length > 0 &&
                              seeMore === false ? (
                              milestone?.deliverables?.map((e, index) => {
                                return index <= 1 ? (
                                  <div>
                                    <ReadMore>{e?.deliverableTitle}</ReadMore>
                                    <div>
                                      <ReadMore>{e?.deliverableDesc}</ReadMore>
                                    </div>
                                    {index !=
                                      _get(gig, 'deliverablesAndLocation.deliverables', []).length - 1 && (
                                        <Divider
                                          sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                        />
                                      )}
                                  </div>
                                ) : (
                                  ''
                                );
                              })
                            ) : (
                              <>
                                {milestone?.deliverables.map((e, index) => {
                                  return (
                                    <div>
                                      <ReadMore>{e?.deliverableTitle}</ReadMore>
                                      <div>
                                        <ReadMore>{e?.deliverableDesc}</ReadMore>
                                      </div>
                                      {index !=
                                        _get(gig, 'deliverablesAndLocation.deliverables').length - 1 && (
                                          <Divider
                                            sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                          />
                                        )}
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          {
                            milestone?.deliverables?.length > 2 &&
                              seeMore === false ? (
                              <Box>
                                <Button
                                  onClick={() => setSeeMore(true)}
                                  sx={{
                                    color: '#1976D2',
                                    textTransform: 'capitalize',
                                    fontSize: '15.5px',
                                    marginLeft: '-9px',
                                    marginTop: '20px'
                                  }}
                                >
                                  See more(
                                  {milestone?.deliverables?.length - 2})
                                </Button>
                              </Box>
                            ) : seeMore === true ? (
                              <Box>
                                <Button
                                  onClick={() => setSeeMore(false)}
                                  sx={{
                                    color: '#1976D2',
                                    textTransform: 'capitalize',
                                    fontSize: '15.5px',
                                    marginLeft: '-9px',
                                    marginTop: '20px'
                                  }}
                                >
                                  See less(
                                  {milestone?.deliverables?.length - 2})
                                </Button>
                              </Box>
                            ) : (
                              ''
                            )}
                        </>
                      ) : (
                        <div className="TandC-Sub-Text">No deliverables found.</div>
                      )}
                    </div>
                  </div>
                  {index !=
                    (milestones.length > 0 ? milestones : gig?.milestones)?.length - 1 && (
                      <Divider
                        sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                      />
                    )}
                </div>
              ))
          }
        </div>
        <HorizontalLine />
        {!_isEmpty(_get(gig, 'deliverablesAndLocation.specialrequirements.file')) &&
          <div>
            <div className="Attactment-Container-gig-detail">
              <div className="Attactment-title-Text-gig">
                Attachments (
                {_get(gig, 'deliverablesAndLocation.specialrequirements.file', '') ? '1' : '0'}){' '}
              </div>
              {_get(gig, 'deliverablesAndLocation.specialrequirements.file', '') ? (
                <div className="attachment-icon-gig-details">
                  <img src={Boundingbox} sx={{ width: '11px', height: '20px' }} />
                  <div
                    className="Attactment-Button-gig"
                    onClick={() =>
                      downloadAttachment(
                        _get(
                          gig,
                          'deliverablesAndLocation.specialrequirements.file',
                          'Not Provided'
                        )
                      )
                    }
                  >
                    <div className="Att-Btn-Text">
                      {
                        _get(gig, 'deliverablesAndLocation.specialrequirements.file', '').split(
                          '/'
                        )[6]
                      }
                    </div>
                  </div>
                </div>
              ) : (
                <p className="att-not-provided-msg">Not Provided</p>
              )}
            </div>
          </div>
        }

        {!_isEmpty(_get(gig, 'deliverablesAndLocation.specialrequirements.details')) &&
          <div>
            <div className="Tand-Ccontainer">
              <div className="TandC-title-Text">Special Terms & Conditions</div>
              {!!_get(gig, 'deliverablesAndLocation.specialrequirements.details') && (
                <ReadMore>
                  {_get(
                    gig,
                    'deliverablesAndLocation.specialrequirements.details',
                    'Not Provided'
                  )}
                </ReadMore>
              )}
            </div>
            <div className="break-line-gig-details"></div>
          </div>
        }
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: 3,
          "button": {
            textTransform: "none"
          }
        }}>
        <FormGroup
          sx={{
            color: "#0140AA"
          }}>
          <FormControlLabel control={<Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            sx={{
              color: "#0140AA",
              '&.Mui-checked': {
                color: "#0140AA",
              },
            }} />} label={
              <Box>
                {/* I confirm that by submitting this proposal:
                1.	I accept and agree to the terms outlined in the Non-Disclosure Agreement (NDA), ensuring confidentiality of all shared information.
                2.	I have carefully reviewed and fully understand the scope and requirements of the project.
                3.	My offer complies with and is within the specified budget range provided by the business.* */}
                I confirm that I accept the NDA, understand the project requirements, and acknowledge that my proposal complies with the specified budget and terms.
              </Box>
            } />
        </FormGroup>
        <Stack
          sx={{
            flexDirection: "row",
            gap: 1
          }}>
          <Button variant='outlined' onClick={() => {
            setOpen(false)
            if (callback) {
              callback()
            }
          }}>Back</Button>
          <Button
            disabled={!checked}
            variant='contained'
            sx={{ background: "#3F52B6" }}
            onClick={handleProposal}>Send</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default GigProposalEngineer
