import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Stack,
  Avatar,
  DialogActions,
  Divider
} from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { Box } from '@mui/system';

import { gigDetailId } from '../../services/gig';
import { GiglinkInput } from '../../containers/app.styles';
import { copyToClick, formatDate, getDurationLabel } from '../../utils/helpers';
import { formatDistance } from 'date-fns';
import ReadMore from '../ReadMore';
import Boundingbox from '../../assests/attach_file.png';
import { getProposal, updateProposalStatus } from '../../services/proposal';
import { toast } from 'react-toastify';

export const PROPOSAL_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
};

function HorizontalLine() {
  return <Box sx={{ border: '1px solid #D5E0D5', my: 2 }} />;
}
const GigProposalBusiness = ({ open, setOpen, rowData, callback, gigId }) => {
  const [gigData, setGigData] = useState();
  const [readMoreDetails, setReadMoreDetails] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const gig = gigData?.gig;

  const engineerData = useMemo(() => {
    return {
      name: rowData?.userId?.profileData?.basicInfo?.name || '',
      title: rowData?.userId?.profileData?.myProfile?.title || '',
      profilePic: rowData?.userId?.profileData?.myProfile?.profilePic || '',
      id: rowData?.userId?.profileData?.basicInfo?.userId,
      posted: rowData?.createdAt
        ? formatDistance(new Date(rowData?.createdAt), new Date(), { addSuffix: true })
        : ''
    };
  }, [rowData]);

  const fetchGiGData = async () => {
    const res = await gigDetailId(gigId);
    if (res.data) {
      fetchProposalData(res.data, engineerData.id, (_gigData) => {
        setGigData(_gigData);
      });
    }
  };

  const fetchProposalData = async (_gigData, engineerId, cb) => {
    const res = await getProposal(gigId, engineerId);
    cb({
      ..._gigData,
      gig: {
        ..._gigData.gig,
        milestones: res.data?.milestones
      },
      proposalId: res.data._id
    });
  };

  useEffect(() => {
    if (open) {
      fetchGiGData();
    }
  }, [gigId, open]);

  const handleClose = () => {
    setOpen(false);
    if (callback) {
      callback();
    }
  };

  const cleanUp = () => {
    setGigData()
  }

  const getExpertiseList = (expertise) => {
    return Array.prototype.map.call(expertise, (s) => ` ${s}`).toString();
  };

  const handleProposal = async (status) => {
    const requestData = {
      proposalId: gigData.proposalId,
      gigId: gigId,
      status
    };
    setIsLoading(true);
    try {
      const res = await updateProposalStatus(requestData);
      if (res.status === 200) {
        toast.success(res.message)
      }
      handleClose()
      cleanUp()
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '& .pic': {
            width: '80px',
            height: '100%',
            objectFit: 'contain',
            borderRadius: '50%'
          },
          '& .close': {
            width: '72px',
            height: '100%',
            objectFit: 'contain'
          }
        }}
      >
        <Stack sx={{ pt: 2 }}>
          {engineerData?.profilePic ? (
            <img src={engineerData.profilePic} alt="pic" className="pic" />
          ) : (
            <Avatar
              src={''}
              sx={{
                width: 80,
                height: 80,
                boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
                margin: 'auto'
              }}
            />
          )}
          <Typography
            sx={{
              mt: 1,
              fontSiz: '14px',
              fontWeight: 600
            }}
          >
            {engineerData.name}
          </Typography>
        </Stack>
        <IconButton disableRipple onClick={handleClose} size="small">
          <img src={'/images/close.png'} alt="close" className="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{ my: 2 }}>
          <Stack>
            <Typography
              component="h5"
              sx={{
                fontSize: '35px',
                fontWeight: 400,
                lineHeight: '35px',
                color: '#001E00'
              }}
            >
              {engineerData.title}
            </Typography>
            <Typography
              sx={{
                mt: 2,
                fontSize: '13px',
                lineHeight: '16px',
                color: '#5E6D55'
              }}
            >
              Posted {engineerData.posted}
            </Typography>
          </Stack>
          <div className="Gig-link-gig-details">
            <div className="Gig-link-Text">Gig Link</div>
            <GiglinkInput
              value={`${window.location.origin}/gig/public/${gigId}`}
              id="CopytoClickid"
              title={`${window.location.origin}/gig/public/${gigId}`}
              disabled
            />
            <div className="Copy-Gig-link-Btn" onClick={copyToClick}>
              <Button className="copy-btn-gig-details">Copy Link</Button>
            </div>
          </div>
        </Stack>
        <HorizontalLine />
        <div className="Project-Detail-Container">
          <div className="PD-title-Text">Project Details</div>
          <Box sx={{ fontSize: '15.5px', color: 'black' }}>
            {!!_get(gig, 'titleAndSkills.projectDetails', '') && (
              <div
                style={{ maxHeight: readMoreDetails ? 'fit-content' : 'auto', overflow: 'hidden' }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: _get(gigData?.gig, 'titleAndSkills.projectDetails', '')
                  }}
                ></div>
              </div>
            )}

            {!!_get(gig, 'titleAndSkills.projectDetails', '') &&
              _get(gig, 'titleAndSkills.projectDetails', '').length > 353 && (
                <button
                  className="project-title-see-more-btn"
                  onClick={() => {
                    setReadMoreDetails(!readMoreDetails);
                  }}
                >{`${readMoreDetails ? 'See Less' : 'See More'}`}</button>
              )}
          </Box>
        </div>
        <HorizontalLine />
        <Box className="Skills-Container-gig-details">
          <Box className="Skills-Required">
            <Box className="Skills-Required-Flex">
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Location.svg"
                width={20}
              />
              <Box className="Skills-Required-title-Text">Location</Box>
            </Box>
            <Box className="Skills-Required-Location">
              {gig?.deliverablesAndLocation?.location?.country
                ? gig?.deliverablesAndLocation?.location?.country
                : 'Remote'}
            </Box>
          </Box>

          <Box className="Skills-Required">
            <Box className="Skills-Required-Flex">
              {gig?.scopeAndBudget?.durationUnit === 'PERHOUR' ? (
                <img
                  src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Hours.svg"
                  width={20}
                />
              ) : gig?.scopeAndBudget?.durationUnit === 'PERDAY' ? (
                <img
                  src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Days.svg"
                  width={20}
                />
              ) : gig?.scopeAndBudget?.durationUnit === 'PERWEEK' ? (
                <img
                  src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Weeks.svg"
                  width={20}
                />
              ) : gig?.scopeAndBudget?.durationUnit === 'PERMONTH' ? (
                <img
                  src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Months.svg"
                  width={20}
                />
              ) : (
                <img
                  src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Hours.svg"
                  width={20}
                />
              )}
              <Box className="Skills-Required-title-Text">Duration</Box>
            </Box>
            <Box className="Skills-Required-subText">
              {_get(gig, 'scopeAndBudget.projectDuration', '-')}{' '}
              {getDurationLabel(_get(gig, 'scopeAndBudget.durationUnit'))}
            </Box>
          </Box>

          <Box className="Skills-Required">
            <Box className="Skills-Required-Flex">
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Budget.svg"
                width={20}
              />
              <Box className="Skills-Required-title-Text">Budget</Box>
            </Box>
            <Box className="Skill-Required-Budget">
              {' '}
              {_get(gig, 'scopeAndBudget.currency', '-')} {_get(gig, 'scopeAndBudget.budget', '-')}
            </Box>
          </Box>

          <Box className="Skills-Required">
            <Box className="Skills-Required-Flex">
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Availability+Required.svg"
                width={12}
              />
              <Box className="Skills-Required-title-Text">Availability Required</Box>
            </Box>

            <Box className="Skills-Required-subText">
              {' '}
              {_get(gig, 'scopeAndBudget.availabilityHoursPerWeek', '')} Hours Per Week
            </Box>
          </Box>

          <Box className="Skills-Required">
            <Box className="Skills-Required-Flex">
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Start+Date.svg"
                width={16}
              />
              <Box className="Skills-Required-title-Text">Start Date</Box>
            </Box>
            <Box className="Skills-Required-subText">
              {' '}
              {_get(gig, 'scopeAndBudget.startDate', '')
                ? formatDate(_get(gig, 'scopeAndBudget.startDate', ''))
                : '-'}{' '}
            </Box>
          </Box>

          {gig?.scopeAndBudget?.hourlyRate === '' || gig?.scopeAndBudget?.hourlyRate === 0 ? (
            ''
          ) : (
            <Box className="Skills-Required">
              <Box className="Skills-Required-Flex">
                <img
                  src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Hourly+Rate.svg"
                  width={16}
                />
                <Box className="Skills-Required-title-Text">Hourly Rate</Box>
              </Box>
              <Box className="Skills-Required-subText">
                {_get(gig, 'scopeAndBudget.hourlyRate', '0')
                  ? `${_get(gig, 'scopeAndBudget.currency', 'R')} ${_get(
                    gig,
                    'scopeAndBudget.hourlyRate',
                    '0'
                  )}/hr`
                  : '-'}
              </Box>
            </Box>
          )}

          <Box className="Skills-Required">
            <Box className="Skills-Required-Flex">
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Area+Of+Expertise.svg"
                width="8%"
              />
              <Box className="Skills-Required-title-Text">Area of Expertise</Box>
            </Box>
            <Box className="Skills-Required-subText">
              {' '}
              {getExpertiseList(_get(gig, 'fieldOfSpecialization', []))}
            </Box>
          </Box>
        </Box>
        <div className="Tand-Ccontainer">
          <div className="break-line-gig-details"></div>
          <div className="TandC-title-Text">Milestones</div>
          {gig?.milestones?.map((milestone, index) => (
            <div className="TandC-Sub-Text">
              <div className="TandC-Sub-Text-Title">{milestone?.title}</div>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 1,
                  '& span': {
                    fontSize: '14px',
                    mr: 1
                  }
                }}
              >
                <Box>
                  <Typography component={'span'}>Milestone Amount:</Typography>
                  <Typography component={'span'}>
                    {milestone.amount}
                    {gig?.scopeAndBudget.currency || 'R'}
                  </Typography>
                </Box>
                <Box>
                  <Typography component={'span'}>Milestone End date:</Typography>
                  <Typography component={'span'}>{formatDate(milestone.deadline)}</Typography>
                </Box>
              </Box>
              <div className="TandC-Sub-Text-Title">
                <ReadMore>{milestone?.description}</ReadMore>
              </div>
              <div>
                <div className="TandC-title-Text">Deliverables</div>
                <div className="TandC-Sub-Text">
                  {milestone?.deliverables?.length > 0 ? (
                    <>
                      {milestone?.deliverables?.length > 0 && seeMore === false ? (
                        milestone?.deliverables?.map((e, index) => {
                          return index <= 1 ? (
                            <div>
                              <ReadMore>{e?.deliverableTitle}</ReadMore>
                              <div>
                                <ReadMore>{e?.deliverableDesc}</ReadMore>
                              </div>
                              {index !=
                                _get(gig, 'deliverablesAndLocation.deliverables', []).length -
                                1 && (
                                  <Divider
                                    sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                  />
                                )}
                            </div>
                          ) : (
                            ''
                          );
                        })
                      ) : (
                        <>
                          {milestone?.deliverables.map((e, index) => {
                            return (
                              <div>
                                <ReadMore>{e?.deliverableTitle}</ReadMore>
                                <div>
                                  <ReadMore>{e?.deliverableDesc}</ReadMore>
                                </div>
                                {index !=
                                  _get(gig, 'deliverablesAndLocation.deliverables').length - 1 && (
                                    <Divider
                                      sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                    />
                                  )}
                              </div>
                            );
                          })}
                        </>
                      )}
                      {milestone?.deliverables?.length > 2 && seeMore === false ? (
                        <Box>
                          <Button
                            onClick={() => setSeeMore(true)}
                            sx={{
                              color: '#1976D2',
                              textTransform: 'capitalize',
                              fontSize: '15.5px',
                              marginLeft: '-9px',
                              marginTop: '20px'
                            }}
                          >
                            See more(
                            {milestone?.deliverables?.length - 2})
                          </Button>
                        </Box>
                      ) : seeMore === true ? (
                        <Box>
                          <Button
                            onClick={() => setSeeMore(false)}
                            sx={{
                              color: '#1976D2',
                              textTransform: 'capitalize',
                              fontSize: '15.5px',
                              marginLeft: '-9px',
                              marginTop: '20px'
                            }}
                          >
                            See less(
                            {milestone?.deliverables?.length - 2})
                          </Button>
                        </Box>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <div className="TandC-Sub-Text">No deliverables found.</div>
                  )}
                </div>
              </div>
              {index !=
                 gig?.milestones?.length - 1 && (
                  <Divider
                    sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                  />
                )}
            </div>
          ))}
        </div>
        <HorizontalLine />
        {!_isEmpty(_get(gig, 'deliverablesAndLocation.specialrequirements.file')) && (
          <div>
            <div className="Attactment-Container-gig-detail">
              <div className="Attactment-title-Text-gig">
                Attachments (
                {_get(gig, 'deliverablesAndLocation.specialrequirements.file', '') ? '1' : '0'}){' '}
              </div>
              {_get(gig, 'deliverablesAndLocation.specialrequirements.file', '') ? (
                <div className="attachment-icon-gig-details">
                  <img src={Boundingbox} sx={{ width: '11px', height: '20px' }} />
                  <div
                    className="Attactment-Button-gig"
                    onClick={() =>
                      downloadAttachment(
                        _get(
                          gig,
                          'deliverablesAndLocation.specialrequirements.file',
                          'Not Provided'
                        )
                      )
                    }
                  >
                    <div className="Att-Btn-Text">
                      {
                        _get(gig, 'deliverablesAndLocation.specialrequirements.file', '').split(
                          '/'
                        )[6]
                      }
                    </div>
                  </div>
                </div>
              ) : (
                <p className="att-not-provided-msg">Not Provided</p>
              )}
            </div>
          </div>
        )}

        {!_isEmpty(_get(gig, 'deliverablesAndLocation.specialrequirements.details')) && (
          <div>
            <div className="Tand-Ccontainer">
              <div className="TandC-title-Text">Special Terms & Conditions</div>
              {!!_get(gig, 'deliverablesAndLocation.specialrequirements.details') && (
                <ReadMore>
                  {_get(gig, 'deliverablesAndLocation.specialrequirements.details', 'Not Provided')}
                </ReadMore>
              )}
            </div>
            <div className="break-line-gig-details"></div>
          </div>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          button: {
            textTransform: 'none'
          }
        }}
      >
        <Button
          variant="contained"
          sx={{ background: '#C00F0C' }}
          disabled={isLoading}
          onClick={() => handleProposal(PROPOSAL_STATUS.REJECTED)}
        >
          Reject Proposal
        </Button>
        <Button
          variant="contained"
          sx={{ background: '#0140AA' }}
          disabled={isLoading}
          onClick={() => handleProposal(PROPOSAL_STATUS.APPROVED)}
        >
          Accept Proposal
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GigProposalBusiness;
