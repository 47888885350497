import React, { useMemo, useState } from 'react';
import { Link, Typography, Box, FormControlLabel, Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button, CircularProgress, FormGroup, Checkbox } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { formatDate } from '../../utils/helpers';
import { addDays } from 'date-fns';
import { useSelector } from 'react-redux';

const GigWorkContract = ({
  showModal,
  setShowModal,
  callback,
  rowData,
  gigData, }) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const profile = useSelector(state => state.profile);
  const [checked, setChecked] = useState(true);
  const handleClose = () => {
    setShowModal(false);
  }

  // console.log("rowData", gigData, rowData, profile)
  const onClick = async () => {
    try {
      if (callback) {
        setIsLoading(true);
        await callback(rowData);
        setIsLoading(false);
      }
      history.push(`/gig/milestone-payment/${gig._id}`)
    } catch (error) {
      setIsLoading(false);
    }
  }

  const engineerData = useMemo(() => {
    return {
      name: rowData?.userId?.profileData?.basicInfo?.name || '',
      title: rowData?.userId?.profileData?.myProfile?.title || '',
      profilePic: rowData?.userId?.profileData?.myProfile?.profilePic || '',
      id: rowData?.userId?.profileData?.basicInfo?.userId,
    };
  }, [rowData]);
  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      fullWidth
      maxWidth="md">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          "& .logo": {
            width: "300px",
            height: "100%",
            objectFit: "contain"
          },
          "& .close": {
            width: "72px",
            height: "100%",
            objectFit: "contain"
          }
        }}>
        <img src={"/images/logo.png"} alt='logo' className='logo' />
        <IconButton
          disableRipple
          onClick={handleClose}
          size='small'>
          <img src={"/images/close.png"} alt='close' className='close' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{
          fontFamily: 'Arial, sans-serif',
          padding: 2,
          "& h6": {
            fontSize: "16px",
            fontWeight: 600,
            "span": {
              fontSize: "16px",
              fontWeight: 400,
            }
          },
          "ul": {
            mt: 0
          },
          "ol": {
            mt: 0
          },
          "& .MuiTypography-body1": {
            my: 1
          }
        }}>
          <Typography variant="h4" align="center" sx={{ marginBottom: 3, fontSize: "24px", fontWeight: 600 }}>
            Gig Work Contract for Engineering Services
          </Typography>

          {/* <Typography variant="h6">Parties Involved:</Typography> */}
          <Typography variant="h6" sx={{ mt: 1 }}>Client: <Typography component={"span"}>{profile?.companyDetails?.companyName}</Typography></Typography>
          <Typography variant="h6" sx={{ mt: 1 }}>Engineer: <Typography component={"span"}>{engineerData?.name}</Typography></Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>Project Description:</Typography>
          <Box
            dangerouslySetInnerHTML={{ __html: gigData?.titleAndSkills?.projectDetails || "" }} />
          {/* <Typography variant="h6" sx={{ mt: 1 }}>Contract ID: <Typography component={"span"}>{engineerData?.name}</Typography></Typography> */}

          <Typography variant="h6" sx={{ mt: 1 }}>Job Type: <Typography component={"span"}>
            {
              gigData?.deliverablesAndLocation?.location?.country
                ? gigData?.deliverablesAndLocation?.location?.country
                : 'Remote'
            }
          </Typography>
          </Typography>

          <Typography variant="h6" sx={{ mt: 1 }}>Location of work and/or project: <Typography component={"span"}>
            {
              gigData?.deliverablesAndLocation?.location?.country
                ? gigData?.deliverablesAndLocation?.location?.country
                : ''
            }
          </Typography>
          </Typography>
          <Typography variant="h6" sx={{ mt: 1 }}>Milestones and Payments:</Typography>
          <ol>
            {
              rowData?.proposalData?.milestones?.map((milestone, index) => {
                return (
                  <li key={index}>
                    <Typography variant="h6">
                      Milestone {index + 1}:
                      <Typography component={"span"} sx={{ ml: 1 }}>
                        {milestone?.title} - {gigData?.scopeAndBudget.currency || 'R'} {milestone?.amount}
                      </Typography>
                    </Typography>
                  </li>
                )
              })
            }
          </ol>

          <Typography variant="h6" sx={{ mt: 1 }}>
            Total Contract Amount: <Typography component={"span"}>{gigData?.scopeAndBudget.currency || 'R'} {gigData?.scopeAndBudget?.budget}</Typography>
          </Typography>

          <Typography variant="h6" sx={{ mt: 1 }}>Timeline:</Typography>
          <ul>
            <li>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Typography variant="h6">Start Date:
                  <Typography component={"span"} sx={{ ml: 1 }}>
                    {
                      gigData?.scopeAndBudget?.startDate && formatDate(gigData?.scopeAndBudget?.startDate)
                    }
                  </Typography>
                </Typography>
                <Typography component={"span"}>
                  -
                </Typography>
                <Typography variant="h6">End Date:
                  <Typography component={"span"} sx={{ ml: 1 }}>
                    {
                      (gigData?.scopeAndBudget?.startDate &&
                        gigData?.scopeAndBudget?.projectDurationInDays)
                      &&
                      formatDate(addDays(new Date(gigData?.scopeAndBudget?.startDate), gigData?.scopeAndBudget?.projectDurationInDays || 0))
                    }
                  </Typography>
                </Typography>
              </Box>
            </li>
            {

              rowData?.proposalData?.milestones?.map((milestone, index) => {
                return (
                  <li key={index}>
                    <Typography variant="h6">
                      Milestone {index + 1} Due:
                      <Typography component={"span"} sx={{ ml: 1 }}>
                        {formatDate(milestone?.deadline)}
                      </Typography>
                    </Typography>
                  </li>
                )
              })
            }
          </ul>

          <Typography variant="h6" sx={{ my: 2 }}>Responsibilities:</Typography>

          <Typography variant="body1">
            <strong>Engineer:</strong> Perform the engineering services as described, ensuring all deliverables meet the
            agreed-upon specifications and quality standards. The engineer warrants that the deliverables will
            meet the agreed-upon specifications and quality standards. Any defects or issues identified within 5
            days of delivery will be rectified by the engineer at no additional cost.
          </Typography>

          <Typography variant="body1">
            <strong>Business:</strong> : Provide all necessary information and support required for the engineer to complete the
            project. Payments for each milestone will be made within 3 business days of milestone completion,
            unless agreed otherwise. The business will manage all payments directly with the engineer.
          </Typography>

          <Typography variant="body1">
            <strong>Acceptance of Milestones</strong> : Milestones will be considered complete when the engineer submits the
            deliverables and the business verifies and accepts them. If there are any discrepancies, the
            business will notify the engineer within 5 days for rectification, unless agreed otherwise.
          </Typography>

          <Typography variant="body1">
            <strong>Scope Changes</strong> : Any modifications to the project scope agreed upon by the engineer and the
            business must be documented via the chat feature on the Gig Engineer platform, which serves as
            the official communication channel. Both parties are required to confirm scope changes in writing
            within the chat. Once the engineer submits a change request and it is approved by the business, a
            revised contract will be generated with an updated revision number. This updated contract will
            outline the details of the changes, including their impact on milestones and payments.
          </Typography>

          <Typography variant="body1">
            <strong>Intellectual Property</strong> : All intellectual property rights created during this project are owned by
            [Business Name]. The engineer retains ownership of pre-existing IP, granting the business a nonexclusive, perpetual, royalty-free license to use it. Upon final payment, the engineer assigns all new
            IP rights to the business. Both parties will keep all IP and project-related information confidential.
          </Typography>

          <Typography variant="body1">
            <strong>Governing Law</strong> : The location of work identifies the place of jurisdiction of this agreement, unless
            otherwise agreed by the two parties in writing in the chat feature.
          </Typography>

          <Typography variant="body1">
            <strong>Confidentiality</strong> : Both parties agree to comply with global privacy standards, including GDPR and
            other relevant regulations. The engineer will keep all project-related information confidential and not
            disclose any details to third parties without the business's consent. The business agrees to keep all
            personal information provided by the engineer confidential.
          </Typography>

          <Typography variant="body1">
            <strong>Compliance with Local Laws</strong> : Both parties agree to comply with global privacy standards, including GDPR and
            other relevant regulations. The engineer will keep all project-related information confidential and not
            disclose any details to third parties without the business's consent. The business agrees to keep all
            personal information provided by the engineer confidential.
          </Typography>

          <Typography variant="body1">
            <strong>Force Majeure</strong> : Neither party shall be liable for any delays or failure to perform due to causes
            beyond their reasonable control, including but not limited to natural disasters, pandemics,
            government actions, or other extraordinary circumstances. In such events, the affected party shall
            notify the other party as soon as possible, and both parties shall work together to find a mutually
            agreeable solution.
          </Typography>

          <Typography variant="body1">
            <strong>Liability and Indemnification</strong> : Each party agrees to indemnify and hold the other harmless from
            any claims, damages, or liabilities arising out of their actions or omissions under this contract. The
            engineer's liability for any claims arising out of this contract shall not exceed the total contract
            amount, except for gross negligence or wilful misconduct.
            agreeable solution.
          </Typography>

          <Typography variant="body1">
            <strong>Termination</strong> : This contract may be terminated at any time by mutual agreement of both parties.
            Either party may also terminate the contract unilaterally for cause, with a notice period of 10 days,
            specifying the grounds for termination. In the event of termination, the engineer will be compensated
            for work completed up to the last completed milestone, including any work completed for the last
            initiated milestone.
          </Typography>

          <Typography variant="body1">
            <strong>Contract Integration</strong> : This contract, together with any communications made through our chat and
            documents uploaded within it, forms the entire agreement between the parties. In case of any
            discrepancies between this contract and the chat communications or uploaded documents, the
            provisions of this contract shall prevail unless explicitly agreed otherwise by both parties in writing.
          </Typography>

          <Typography variant="body1">
            <strong>Gig Engineer’s Overall Terms and Conditions</strong> : For definitions and general terms and conditions
            governing this engagement, please refer to the Gig Engineer’s website [add link here].
          </Typography>

          <Typography variant="body1">
            <strong>Disclaimer</strong> : gigengineer.io is an online platform that facilitates the connection between businesses
            and engineers. This contract is provided by gigengineer.io for convenience but does not imply
            ownership or any responsibility for the terms agreed upon between the business and the engineer.
          </Typography>
        </Box>
        <FormGroup
          sx={{
            color: "#0140AA"
          }}>
          <FormControlLabel control={<Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            sx={{
              color: "#0140AA",
              '&.Mui-checked': {
                color: "#0140AA",
              },
            }} />} label="By checking this box, you are agreeing to our terms of service." />
        </FormGroup>
        <Box sx={{ my: 2, border: "0.82px solid #D5E0D5" }} />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>Cancel</Button>
        <Button
          disabled={!checked || isLoading}
          variant='contained'
          sx={{ background: "#0140AA" }}
          onClick={onClick}>Approve {isLoading && <CircularProgress size={20} color='info' sx={{ ml: 1 }} />}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GigWorkContract;
