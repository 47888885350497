import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  SvgIcon,
  Paper,
  InputBase,
  IconButton
} from '@mui/material';
import _ from 'lodash';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _indexOf from 'lodash/indexOf';
import { PaddedBox } from '../app.styles';
import ChatMessages from './ChatMessages';
import { getQueryParams } from '../../utils/helpers';
import { setChatMessage, setChatNewUser, setSelectedUser } from '../../store/actions/chat';
import { getTokenDetails } from '../../utils/user';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _map from 'lodash/map';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { getUserType } from '../../utils/user';
import BusinessIcon from '../../assests/Business.svg';
import BusinessIconSelected from '../../assests/BusinessSelected.svg';
import SortIcon from '../../assests/SortIcon.svg';
import SortIconUp from '../../assests/SortIconUp.svg';
import chatEngineer from '../../assests/chat-engineer-side.png';
import './chat.css';
import SearchIcon from '@mui/icons-material/Search';
import VerifiedIcon from '../../assests/VerifiedTick.svg';
import { useParams } from 'react-router-dom';

const Chat = (props) => {
  const tokenDetails = getTokenDetails();
  const token = tokenDetails.userId;
  let actionkey = '';
  const query = getQueryParams();

  const [key, setKey] = useState(actionkey);
  const [expandedGig, setExpandedGig] = useState();
  const [activeGigData, setActiveGigData] = useState();
  const [activeChatData, setActiveChatData] = useState();
  const [searchText, setSearchText] = useState();
  const [sortBy, setSortBy] = useState('desc');
  const userType = 'engineer';
  const chatBusiness = 'https://localhost';
  const onChangeOrderChange = () => setSortBy((prev) => (prev === 'desc' ? 'asc' : 'desc'));
  const sendMessageTo = (messageObject) => {
    socket.emit('privatemessage', {
      ...activeChatData,
      ...messageObject
    });
    props.setChatMessage({
      ...activeChatData,
      ...messageObject
    });
  };

  const onSelectUserToChat = (_gigData, _application, _userData) => {
    if (_application?.applicationInfo) {
      const newReceivedId =
        tokenDetails.userId === _application.applicationInfo.adminId
          ? _application.applicationInfo.userId
          : _application.applicationInfo.adminId;
      const processedChatData = {
        gigId: _gigData._id,
        cId: _application.applicationInfo._id,
        name: props.profile.basicInfo.name || 'Anonymous',
        senderId: tokenDetails.userId,
        receiverId: newReceivedId,
        senderProfilePic: 'https://',
        receiverProfilePic: 'https://'
      };
      props.setSelectedUser(_application.applicationInfo._id);
      setActiveGigData(_gigData);
      setActiveChatData(processedChatData);
    }
  };
  const chatContactsList = useMemo(() => {
    let newData = props.users;
    if (_.get(props, 'users', []).length && _.isString(searchText)) {
      const searchedGigs = props.users.filter((contact) =>
        String(_.get(contact, 'gig.titleAndSkills.projectTitle', ''))
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
      newData = searchedGigs;
    }
    const sortedArray = newData.sort((a, b) => {
      const timestampA = new Date(a.applications[0].lastMessageTimestamp).getTime();
      const timestampB = new Date(b.applications[0].lastMessageTimestamp).getTime();
      return timestampB - timestampA;
    });

    return sortedArray;
  }, [props.users, searchText, sortBy]);
  const getListIdentifier = (val) => {
    return val.replace(`${token}-`, '').replace(`-${token}`, '');
  };

  useEffect(() => {
    if (query?.applicationId && query?.gigId && chatContactsList.length && !activeChatData) {
      const contactItem = chatContactsList.find(
        (contactItem) => contactItem.gig._id === query?.gigId
      );
      const applicationInfo = contactItem?.applications.find(
        (applicationItem) => applicationItem.applicationInfo?._id === query.applicationId
      );
      if (contactItem?.gig) {
        setExpandedGig(contactItem?.gig._id);
        onSelectUserToChat(contactItem?.gig, applicationInfo);
      }
    }
  }, [query, chatContactsList]);

  useEffect(() => {
    // if (sendMsgto) {
    //   const chatUser = {
    //     chatId: key,
    //     name: sendMsgto,
    //     otherUserId: sendMsgto
    //   };
    //   socket.emit('get-user-name', sendMsgto);
    //   props.setChatNewUser(chatUser);
    // }
    socket.emit('get-all-users', token);
    return () => {
      props.setSelectedUser('');
      props.setChatNewUser({});
    };
  }, []);

  useEffect(() => {
    if (key === '' && props.users.length > 0) {
      // const selUser = props.users[0];
      // setKey(selUser.chatId);
      // setSendMsgTo(getListIdentifier(selUser.chatId));
      // props.setSelectedUser(selUser.chatId);
    }
  }, [props.users]);

  const SearchContainer = () => {
    return (
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
        style={{
          boxShadow: 'none',
          border: '1px solid #00000033',
          borderRadius: '18px'
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          value={searchText}
          autoFocus
          onChange={(e) => setSearchText(e.target.value)}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
    );
  };

  return (
    <>
      <PaddedBox
        style={{
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '240px'
              : '0px'
          }`
        }}
      >
        <Box className="chat-Box">
          <Card className="chat-SideBar" sx={{ overflowY: 'auto' }}>
            <ListItem style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography style={{ flex: 1 }}>Active Gig & Conversations</Typography>
              <img
                loading="lazy"
                src={sortBy === 'desc' ? SortIcon : SortIconUp}
                onClick={onChangeOrderChange}
              />
            </ListItem>
            <ListItem style={{ display: 'flex', flexDirection: 'row' }}>
              <SearchContainer />
            </ListItem>
            <CardContent sx={{ padding: '0' }}>
              <List sx={{ paddingTop: '10px' }}>
                {chatContactsList.map((chatItem) => (
                  <Accordion
                    expanded={expandedGig === chatItem?.gig._id}
                    className="chat-sidebar-container"
                    onChange={(e, expanded) => setExpandedGig(expanded ? chatItem.gig._id : null)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          style={{
                            color: expandedGig === chatItem.gig._id ? 'white' : '#1B1F26B8'
                          }}
                        />
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className={`${expandedGig === chatItem.gig._id ? 'chat-Selected-gig' : ''}`}
                    >
                      <ListItem style={{ display: 'flex', flexDirection: 'row' }}>
                        <img
                          loading="lazy"
                          src={
                            expandedGig === chatItem.gig._id ? BusinessIconSelected : BusinessIcon
                          }
                        />
                        <Typography
                          className={`chat-List-Item-gig-name ${
                            expandedGig === chatItem.gig._id
                              ? 'chat-List-Item-gig-name-selected'
                              : ''
                          }`}
                          ml={2}
                        >
                          {chatItem?.gig?.titleAndSkills?.projectTitle}
                        </Typography>
                      </ListItem>
                    </AccordionSummary>
                    <AccordionDetails className="chat-List-Item">
                      {chatItem?.applications.map((application) => {
                        return (
                          <div>
                            {_map(application.chats, (value, indexKey) => (
                              <>
                                {value.otherUserId !== token && (
                                  <ListItem
                                    className={` ${
                                      activeChatData?.cId === value.chatId
                                        ? 'chat-Selected'
                                        : 'chat-selected-none'
                                    }`}
                                    key={Math.random()}
                                    data-value={getListIdentifier(value.chatId)}
                                    onClick={() =>
                                      onSelectUserToChat(chatItem.gig, application, value)
                                    }
                                  >
                                    <Avatar
                                      alt={'image'}
                                      className="chat-Item-Img"
                                      src={value.profilePic ? value.profilePic : ''}
                                    ></Avatar>
                                    <div className="chat-name-left">
                                      <ListItemText
                                        className="chat-Item-Name"
                                        primary={value.name}
                                        sx={{ marginLeft: '10px' }}
                                      />
                                      <div className="icon-tick-chat">
                                        {!!value?.isVerified &&
                                          value?.profileCompletionPercent == 100 && (
                                            <img
                                              className="verified-name-ico"
                                              src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Blue+Tick+Verification.svg"
                                            />
                                          )}
                                      </div>
                                    </div>
                                    {_indexOf(props.chatNotifications, value.chatId) !== -1 && (
                                      <span className="chat-Notification"></span>
                                    )}
                                  </ListItem>
                                )}
                              </>
                            ))}
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </List>
            </CardContent>
          </Card>
          {!activeChatData && (
            <div className="chat__msger">
              {userType === 'business' && (
                <img
                  loading="lazy"
                  src={chatBusiness}
                  alt="No messages yet"
                  title="No Message, Yet!"
                />
              )}
              {userType === 'engineer' && (
                <img
                  loading="lazy"
                  src={chatEngineer}
                  alt="No messages yet"
                  title="No Message, Yet!"
                />
              )}
            </div>
          )}
          {activeChatData && (
            <ChatMessages
              gigData={activeGigData}
              chatData={activeChatData}
              sendMsg={sendMessageTo}
            ></ChatMessages>
          )}
        </Box>
      </PaddedBox>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    auth: state.auth,
    users: state.chat.users,
    messages: state.chat.messages,
    chatNotifications: state.chat.chatNotifications
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChatMessage: (details) => dispatch(setChatMessage(details)),
    setChatNewUser: (details) => dispatch(setChatNewUser(details)),
    setSelectedUser: (details) => dispatch(setSelectedUser(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
