import apiService, { apiHandler } from "./apiServices";

const initiateTruzoRegistration = async () => {
  const request = {
    method: 'post',
    url: '/truzo/register'
  };
  const html = await apiService(request);

  document.open();
  document.write(html);
  document.close();

};

const getTruzoTransaction = async () => {
  const request = {
    method: 'get',
    url: '/truzo/transaction'
  };
  const res = await apiService(request);
  return res
}

const createTruzoTransaction = async (data) => {
  const request = {
    method: 'post',
    url: '/truzo/transaction',
    data
  };
  const res = await apiHandler(request);
  return res
}

const approveTruzoContract = async (data) => {
  const request = {
    method: 'post',
    url: '/truzo/approve',
    data
  };
  const res = await apiHandler(request);
  return res
}

const getTruzoPaymentLink = async (transactionId) => {
  const request = {
    method: 'get',
    url: `/truzo/payment/${transactionId}`,
  };
  const res = await apiHandler(request);
  return res
}

const checkTruzoPaymentStatus = async (transactionId) => {
  const request = {
    method: 'get',
    url: `/truzo/payment/success/${transactionId}`,
  };
  const res = await apiHandler(request);
  return res
}

const downloadContract = async (transactionId) => {
  const request = {
    method: 'get',
    url: `/gig/contract/${transactionId}`,
  };
  const res = await apiHandler(request);
  return res
}

const deliverTruzoTransaction = async (transactionId) => {
  const request = {
    method: 'get',
    url: `/truzo/deliver/${transactionId}`
  };
  const res = await apiHandler(request);
  return res
}

export {
  initiateTruzoRegistration,
  getTruzoTransaction,
  createTruzoTransaction,
  approveTruzoContract,
  getTruzoPaymentLink,
  checkTruzoPaymentStatus,
  downloadContract,
  deliverTruzoTransaction
}
